import React from "react";
import moment from "moment";
import { TipRaceForm } from ".";
import { ModelWrapper } from "../../../components";

const initialValue = {
  tipRaceTitle: "",
  tipRaceNumber: 1,
  tipRaceGoing: "",
  tipRaceDetail: "",
  tipRaceDate: moment(),
};

function TipFormModel({
  showModal,
  toggleModal,
  onSubmit,
  title,
  subtitle,
  state,
  initialValues = initialValue,
}) {
  if (!showModal) return null;
  return (
    <ModelWrapper
      toggleModal={toggleModal}
      title={title}
      subtitle={subtitle}
      error={state.error}
    >
      <TipRaceForm
        onSubmit={onSubmit}
        state={state}
        onCancel={toggleModal}
        initialValues={initialValues}
      />
    </ModelWrapper>
  );
}

export default TipFormModel;
