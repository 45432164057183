import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { Layout } from "../../components";

// insight pages
import ListingInsight from "./ListingInsight";
// import AddLocation from "./AddLocation";
// import EditLocation from "./EditLocation";

function Insight() {
  const navigate = useNavigate();
  const handleAddClick = () => {
    navigate("/dashboard/insights/add-insight");
  };

  const handleNavigateToListing = () => {
    navigate("/dashboard/insights/");
  };

  const handleEditClick = (insightId) => {
    navigate(`/dashboard/insights/edit-insight/${insightId}`);
  };

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <ListingInsight
              onAdd={handleAddClick}
              onBack={handleNavigateToListing}
              onEdit={handleEditClick}
            />
          }
        />
        {/* <Route
          path="/add-insight"
          element={<AddLocation onBack={handleNavigateToListing} />}
        /> */}
        {/* <Route
          path="/edit-insight/:id"
          element={<EditLocation onBack={handleNavigateToListing} />}
        /> */}
      </Routes>
    </Layout>
  );
}

export default Insight;
