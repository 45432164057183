import { Box, Button, Stack } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import {
  FormImageInput,
  FormVideoInput,
  SubmitLoadingButton,
} from "../../../../../components/Forms";
import { Formik } from "formik";
import RadioButtonGroup from "../../../../../components/Forms/RadioButtonGroup";

const maxFileSize = {
  image: 1 * 1024 * 1024, // 1 MB in bytes
  video: 20 * 1024 * 1024, // 20 MB in bytes
};

const validationSchema = Yup.object().shape({
  insightType: Yup.string().required().min(2).label("Insight Type").trim(),
  checkResolution: Yup.boolean().required().oneOf([false]),

  selectedImage: Yup.mixed().when("insightType", {
    is: "image", // When insightType is 'image'
    then: Yup.mixed()
      .required("Image is required")
      .test(
        "type",
        "Only the following formats are accepted: .jpeg, .jpg, .png, and .webp",
        (value) => {
          return (
            value &&
            (value.type === "image/jpeg" ||
              value.type === "image/png" ||
              value.type === "image/webp" ||
              value.type === "image/jpg")
          );
        }
      )
      .test("size", "File size too large", (value) => {
        return value && value.size <= maxFileSize.image;
      })
      .label("Image"),
    otherwise: Yup.mixed().nullable(), // If not 'image', this field is not required
  }),
  selectedVideo: Yup.mixed().when("insightType", {
    is: "video", // When insightType is 'video'
    then: Yup.mixed()
      .required("Video is required")
      .test(
        "type",
        "Only the following formats are accepted: .mp4, .mov, .avi, and .webm",
        (value) => {
          return (
            value &&
            (value.type === "video/mp4" ||
              value.type === "video/mov" ||
              value.type === "video/quicktime" ||
              value.type === "video/avi" ||
              value.type === "video/webm")
          );
        }
      )
      .test("size", "File size too large", (value) => {
        return value && value.size <= maxFileSize.video;
      })
      .label("Video"),
    otherwise: Yup.mixed().nullable(), // If not 'video', this field is not required
  }),
});

const insightOptions = [
  { key: "Image", value: "image" },
  { key: "Video", value: "video" },
];

function InsightForm({ onSubmit, initialValues, state, toggleModal }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, setFieldTouched, setFieldError, errors }) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <RadioButtonGroup
              name="insightType"
              options={insightOptions}
              onChange={(value) => {
                setFieldValue("selectedImage", "");
                setFieldValue("selectedVideo", "");
              }}
              layout="row"
            />
            {values.insightType === "image" ? (
              <FormImageInput
                name="selectedImage"
                imageValues={values.articleImage}
                onChangeSelectedImage={(event) => {
                  setFieldTouched(event.target.name);
                  setFieldValue(event.target.name, event.target.files[0]);
                }}
                onError={(message) => {
                  setFieldError("selectedImage", message);
                }}
                onCheckRes={(checkResolution) => {
                  setFieldValue("checkResolution", checkResolution);
                }}
                checkResolution={values.checkResolution}
              />
            ) : (
              <FormVideoInput
                name="selectedVideo"
                videoValues={values.articleImage}
                onChangeSelectedVideo={(event) => {
                  setFieldTouched(event.target.name);
                  setFieldValue(event.target.name, event.target.files[0]);
                }}
                onError={(message) => {
                  setFieldError("selectedVideo", message);
                }}
                onCheckRes={(checkResolution) => {
                  setFieldValue("checkResolution", checkResolution);
                }}
                checkResolution={values.checkResolution}
              />
            )}

            <Stack spacing={2} direction="row">
              <Button fullWidth variant="outlined" onClick={toggleModal}>
                CANCEL
              </Button>
              <SubmitLoadingButton
                buttonText="Save"
                type="submit"
                submitButton={true}
                loading={state.loading}
              />
            </Stack>
            {/* <SuccessAndErrorMessage error={state.error} success={state.message} /> */}
          </Box>
        );
      }}
    </Formik>
  );
}

export default InsightForm;
