// import moment from "moment";
import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Stack, Button, Typography, Box } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
// components
import { Loader, ErrorMessage } from "../../../../components";
import { TipRaceFormModel, TipRaceItem } from "./component";
// hooks
import useResponse from "../../../../hooks/useResponse";
import useVisible from "../../../../hooks/useVisible";
// services
import {
  getAllTipRaceByTipLocationId,
  createTipRace,
  deleteTipRace,
  updateTipRace,
} from "../../../../services/tipRaceService";
import * as toast from "../../../../Toast";
// utils
import trimObjectValues from "../../../../utils/trimObject";

function TipLocation() {
  const navigate = useNavigate();
  const params = useParams();
  const [tipRaceList, setTipRaceList] = useState([]);
  const [isAddShow, toggleAddModal] = useVisible(false);
  const [state, dispatch] = useResponse();
  const tipLocationId = params.id;

  const onError = (ex) => {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error } = useQuery(
    ["tip-race", tipLocationId],
    () => getAllTipRaceByTipLocationId(tipLocationId),
    { refetchOnWindowFocus: true, onError }
  );

  const tipLocation = data?.data?.data["tipRaces"];

  useEffect(() => {
    if (tipLocation) {
      setTipRaceList(tipLocation?.tipRaces);
    }
  }, [tipLocation]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const handleSubmit = async (tipRaceData) => {
    dispatch.sendingRequst();
    try {
      const trimTipRaceData = trimObjectValues(tipRaceData);
      const response = await createTipRace({
        tipLocationId,
        ...trimTipRaceData,
      });
      const data = await response.data;
      const { tipRace } = data.data;

      // trimTipRaceData.tipRaceTime = moment(trimTipRaceData.tipRaceDate).format(
      //   "hh:mm A"
      // );

      setTipRaceList((state) => [...state, tipRace]);
      toggleAddModal();
      toast.success(data.message);
      dispatch.successResponse(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  const findIndexOfTipRace = (tipRaceId) => {
    return tipRaceList.findIndex((tipRace) => tipRace.id === tipRaceId);
  };

  const updateTipRaceOfGivenIndex = (index, tipRace) => {
    tipRaceList[index] = tipRace;
    setTipRaceList(tipRaceList);
  };

  const removeTipRaceOfGivenIndex = (index) => {
    tipRaceList.splice(index, 1);
    setTipRaceList(tipRaceList);
  };

  // const handleDeleteConfirm = async () => {
  //   dispatch.sendingRequst();
  //   try {
  //     const response = await deleteTipRace(tipLocation.id);
  //     const data = await response.data;
  //     toggleDeleteMode();

  //     // remove location from state
  //     updateState(tipLocation.id);

  //     toast.success(data.message);
  //     dispatch.successResponse(data.message);
  //   } catch (ex) {
  //     if (
  //       ex.response &&
  //       ex.response.status >= 400 &&
  //       ex.response.status <= 409
  //     ) {
  //       dispatch.gotError(ex.response.data.message);
  //       toast.error(ex.response.data.message);
  //     }
  //   }
  // };

  const handleUpdateTipRace = async (tipRaceData, callBack) => {
    dispatch.sendingRequst();

    const tipRaceId = tipRaceData.id;
    delete tipRaceData.id;
    const trimTipRaceData = trimObjectValues(tipRaceData);
    try {
      const res = await updateTipRace(tipRaceId, {
        tipLocationId,
        ...trimTipRaceData,
      });
      const data = await res.data;

      // trimTipRaceData.tipRaceTime = moment(trimTipRaceData.tipRaceDate).format(
      //   "hh:mm A"
      // );

      trimTipRaceData.id = tipRaceId;

      // updating tipRace in state based on index of tipRace
      const index = findIndexOfTipRace(tipRaceId);
      if (index !== -1) {
        updateTipRaceOfGivenIndex(index, trimTipRaceData);
      }

      callBack();
      dispatch.successResponse(data.message);
      toast.success(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleDeleteTipRace = async (tipRaceId, callBack) => {
    dispatch.sendingRequst();
    try {
      const res = await deleteTipRace(tipRaceId, { tipLocationId });
      const data = await res.data;

      // removing tipRace in state based on index of tipRace
      const index = findIndexOfTipRace(tipRaceId);
      if (index !== -1) {
        removeTipRaceOfGivenIndex(index);
      }

      callBack();
      toast.success(data.message);
      dispatch.successResponse(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <>
      <TipRaceFormModel
        title="Add Tip Race"
        showModal={isAddShow}
        toggleModal={toggleAddModal}
        onSubmit={handleSubmit}
        state={state}
      />
      <Stack spacing={5} padding={3}>
        <Stack
          style={{
            display: "flex",
            // justifyContent: "center",
            // alignSelf: "end",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            size="small"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
            style={{ backgroundColor: "#2087f5" }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            onClick={toggleAddModal}
          >
            Add Race
          </Button>
        </Stack>
        <Stack>
          <Typography variant="h5">
            Tip Races - {tipLocation.locationName}
          </Typography>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: 1,
            }}
          >
            {tipRaceList.map((tipRace) => (
              <TipRaceItem
                tipRace={tipRace}
                key={tipRace.id}
                updateRecord={handleUpdateTipRace}
                deleteRecord={handleDeleteTipRace}
                state={state}
              />
            ))}
          </Box>
        </Stack>
      </Stack>
    </>
  );
}

export default TipLocation;
