import React from "react";
import * as Yup from "yup";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link, Box } from "@mui/material";
import { CardContainer, Card } from "../../components/UI";
import { HeaderTitleSubtitle, SuccessAndErrorMessage } from "../../components";

import {
  Form,
  FormInput,
  FormInputPassword,
  SubmitLoadingButton,
} from "../../components/Forms";

import useResponse from "../../hooks/useResponse";
import trimObjectValues from "../../utils/trimObject";
import * as authService from "../../services/authService";
import * as toast from "../../Toast";

import styles from "./login.module.css";

// login validationSchema
const validationSchema = Yup.object().shape({
  email: Yup.string().required().min(6).email().label("Email").trim(),
  password: Yup.string().required().min(5).max(30).label("Password").trim(),
});

const Login = () => {
  const [state, dispatch] = useResponse();
  const navigate = useNavigate();

  const handleSubmit = async (loginData, formkiObject) => {
    dispatch.sendingRequst();
    // triming object
    loginData = trimObjectValues(loginData);
    try {
      const response = await authService.login(loginData);
      const { data } = await response.data;
      authService.setJWT(data.token);
      // formkiObject.resetForm();
      toast.success("Successfully logged in.");
      dispatch.successResponse(response.data.message);
      navigate("/dashboard/home?page=1");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <CardContainer>
      <Card>
        <div className={styles.headingContainer}>
          <HeaderTitleSubtitle
            title="Login"
            subtitle="Enter your details below."
          />
        </div>
        <Form
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
            }}
          >
            <FormInput label="Email" name="email" type="email" />
            <FormInputPassword
              label="Password"
              name="password"
              type="password"
            />
            <SubmitLoadingButton
              buttonText="Login"
              type="submit"
              submitButton={true}
              loading={state.loading}
            />
            <SuccessAndErrorMessage
              error={state.error}
              success={state.message}
            />
            <Link
              variant="subtitle1"
              component={RouterLink}
              to="/forgot-password"
              align="center"
            >
              Forgot Password?
            </Link>
          </Box>
        </Form>
      </Card>
    </CardContainer>
  );
};

export default Login;
