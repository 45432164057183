import moment from "moment";
import React, { useCallback, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Stack, Button } from "@mui/material";
import { InsightFormModel, ListingTable } from "./components";

// hooks
import useVisible from "../../../../hooks/useVisible";
import useResponse from "../../../../hooks/useResponse";

import * as toast from "../../../../Toast";

import {
  createInsight,
  deleteInsight,
  getAllInsightWithPagination,
} from "../../../../services/insightService";
import { useMutation } from "react-query";

function ListingInsight() {
  const [isAddShow, toggleAddModal] = useVisible(false);
  const [state, dispatch] = useResponse();
  const [refreshState, setRefreshState] = useState(false);

  const onError = (ex) => {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      // navigate("/not-found");
      dispatch.gotError(ex.response.data.message);
      toast.error(ex.response.data.message);
    }
  };

  const onSuccess = (response) => {
    console.log(response);

    const data = response.data;

    toggleAddModal();
    toast.success(data.message);
    dispatch.successResponse(data.message);

    // set refresh state to true, to refresh listing
    setRefreshState(true);

    // Reset state after 4 seconds using setTimeout
    setTimeout(() => {
      setRefreshState(false);
    }, 4000);
  };

  const { mutate, isLoading, error } = useMutation(createInsight, {
    onSuccess,
    onError,
  });

  const handleSubmit = async (submitData) => {
    const formData = new FormData();
    if (submitData.insightType === "image") {
      formData.append("file", submitData.selectedImage);
    } else if (submitData.insightType === "video") {
      formData.append("file", submitData.selectedVideo);
    }

    formData.append("date", new Date().toISOString());

    mutate(formData);
  };

  return (
    <>
      <InsightFormModel
        title="Add Insight"
        showModal={isAddShow}
        toggleModal={() => {
          toggleAddModal();
          dispatch.reset();
        }}
        onSubmit={handleSubmit}
        state={{ loading: isLoading, error: error }}
      />
      <Stack spacing={5} padding={3}>
        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "end",
          }}
        >
          <Button
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            onClick={toggleAddModal}
          >
            Add
          </Button>
        </Stack>
        <Stack>
          <ListingTable
            columns={columns}
            // onEditClick={onEdit}
            controller={useCallback(
              async (data) => await getAllInsightWithPagination(data),
              []
            )}
            deleteRecord={deleteInsight}
            deleteModelTitle="Do you want to delete this Insight?"
            refreshState={refreshState}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default ListingInsight;

const columns = [
  {
    field: "imageName",
    headerName: "Name",
    width: 400,
    renderCell: (params) => {
      return (
        <a
          href={`https://thesixthdayspace.syd1.cdn.digitaloceanspaces.com/${params.row.insightImage}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <p>{params.row.imageName}</p>
        </a>
      );
    },
  },

  {
    field: "insightImage",
    headerName: "Thumbnail",
    width: 100,
    renderCell: (params) => {
      return (
        <a
          href={`https://thesixthdayspace.syd1.cdn.digitaloceanspaces.com/${params.row.insightImage}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.row.insightType === "image" ? (
            <img
              src={`https://thesixthdayspace.syd1.cdn.digitaloceanspaces.com/${params.row.insightImage}`}
              style={{
                width: 40,
                height: 40,
                objectFit: "contain",
              }}
              alt={params.row.imageName}
            />
          ) : (
            <video
              src={`https://thesixthdayspace.syd1.cdn.digitaloceanspaces.com/${params.row.insightImage}`}
              controls
              width={50}
              height={50}
              preload="none" // Prevent video preloading
            />
          )}
        </a>
      );
    },
  },
  {
    field: "validTill",
    headerName: "Valid Till",
    width: 200,
    valueGetter: (params) =>
      `${moment(params.row.validTill).format("DD-MMM YYYY, h:mm a")}`,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 200,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("DD-MMM YYYY, h:mm a")}`,
  },

  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    minWidth: 140,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return <Button color="error">delete</Button>;
    },
  },
];
