import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Field } from "formik";
import React from "react";

function RadioButtonGroup({ name, options, onChange, layout = "column" }) {
  return (
    <Field name={name}>
      {({ field }) => {
        return (
          <FormControl component="fieldset">
            <RadioGroup
              {...field}
              onChange={(event) => {
                field.onChange(event);
                onChange(event.currentTarget.value);
              }}
              style={{
                display: "flex",
                flexDirection: layout === "column" ? "column" : "row",
              }}
            >
              {options.map((option) => (
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  label={option.key}
                  key={option.key}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      }}
    </Field>
  );
}

export default RadioButtonGroup;
