import React from "react";
import { ArticleNotificationForm } from ".";
import { ModelWrapper } from "../../../components";

const initialValue = {
  notificationText: "",
  articleId: "",
};

function ArticleNotificationFormModal({
  showModal,
  toggleModal,
  onSubmit,
  title,
  subtitle,
  initialValues = initialValue,
  articleList = [],
}) {
  if (!showModal) return null;
  return (
    <ModelWrapper toggleModal={toggleModal} title={title} subtitle={subtitle}>
      <ArticleNotificationForm
        onSubmit={onSubmit}
        toggleModal={toggleModal}
        initialValues={initialValues}
        articleList={articleList}
      />
    </ModelWrapper>
  );
}

export default ArticleNotificationFormModal;
