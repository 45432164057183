import { Box, Button, Stack } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import {
  ArticleDropDown,
  Form,
  FormInput,
  SubmitLoadingButton,
} from "../../../../../components/Forms";

// login validationSchema
const validationSchema = Yup.object().shape({
  notificationText: Yup.string()
    .required()
    .min(2)
    .max(200, "Notification Text must be less than 200 characters")
    .label("Notification Text")
    .trim(),
  articleId: Yup.string().required().min(2).label("Article").trim(),
});

const initialValue = {
  notificationText: "",
  articleId: "",
};

function ArticleNotificationForm({
  onSubmit,
  initialValues = initialValue,
  toggleModal,
  articleList = [],
}) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <ArticleDropDown
          label="Article Title"
          name="articleId"
          articleList={articleList}
        />
        <FormInput
          label="Notification Text"
          name="notificationText"
          type="text"
        />

        <Stack spacing={2} direction="row">
          <Button fullWidth variant="outlined" onClick={toggleModal}>
            CANCEL
          </Button>
          <SubmitLoadingButton
            buttonText="Send Notification"
            type="submit"
            submitButton={true}
          />
        </Stack>
        {/* <SuccessAndErrorMessage error={state.error} success={state.message} /> */}
      </Box>
    </Form>
  );
}

export default ArticleNotificationForm;
