import React from "react";
import moment from "moment";

// material ui components
import { Box, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
// components
import { TipRaceFormModel } from ".";
import { ConfirmationModal } from "../../../components";
// hooks
import useVisible from "../../../../../hooks/useVisible";

function TipRaceItem({ tipRace, updateRecord, deleteRecord, state }) {
  const [isDeleteMode, toggleDeleteMode] = useVisible();
  const [isUpdatedMode, toggleUpdateMode] = useVisible();
  const {
    tipRaceTitle,
    tipRaceNumber,
    tipRaceDate,
    // tipRaceTime,
    tipRaceGoing,
    tipRaceDetail,
    id: tipRaceId,
  } = tipRace;

  return (
    <>
      <ConfirmationModal
        title="Do you want to delete this tip race?"
        showModal={isDeleteMode}
        toggleModal={toggleDeleteMode}
        onConfirm={() => deleteRecord(tipRaceId, toggleDeleteMode)}
        loading={state.loading}
        error={state.error}
      />
      <TipRaceFormModel
        title="Update Tip Race"
        showModal={isUpdatedMode}
        toggleModal={toggleUpdateMode}
        initialValues={{
          tipRaceTitle,
          tipRaceNumber,
          // tipRaceDate: moment(tipRaceDate).format("YYYY-MM-DDTHH:mm"),
          tipRaceDate: moment(tipRaceDate),
          tipRaceGoing,
          tipRaceDetail,
          id: tipRaceId,
        }}
        onSubmit={(data) => updateRecord(data, toggleUpdateMode)}
        state={state}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          // alignItems: "center",
          alignItems: "flex-start",
        }}
      >
        <Accordion
          style={{
            width: "100%",
            boxShadow: "none",
            border: "1px solid gray",
            borderRadius: 3,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          > */}
            <Typography
              variant="subtitle1"
              color="textColor.main"
              fontWeight="300"
            >
              {tipRaceTitle} (Race {tipRaceNumber})
            </Typography>
            {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: 7,
            }}
          >
            <Button onClick={toggleUpdateMode}>Edit</Button>
            <Button color="error" onClick={toggleDeleteMode}>
              delete
            </Button>
          </Box> */}
            {/* </Box> */}
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                marginBottom: 1,
              }}
            >
              <ShowRowItem
                title="Date"
                value={moment(tipRaceDate).format("DD MMMM YYYY")}
              />
              <ShowRowItem
                title="Time"
                value={moment(tipRaceDate).format("hh:mm A")}
              />
              <ShowRowItem title="Going" value={tipRaceGoing} />
            </Box>
            <Typography fontWeight={600}>Tips</Typography>
            <Typography color="textColor.main" fontWeight="300">
              {tipRaceDetail}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: 7,
            marginTop: 1,
          }}
        >
          <Button onClick={toggleUpdateMode}>Edit</Button>
          <Button color="error" onClick={toggleDeleteMode}>
            delete
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default TipRaceItem;

const ShowRowItem = ({ title, value }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 1,
    }}
  >
    <Typography variant="body1" fontWeight="300">
      {title}
    </Typography>
    <Typography variant="subtitle1" fontWeight="300">
      {value}
    </Typography>
  </Box>
);
