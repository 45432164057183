import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiEndpoint = http.apiUrl + "admin-auth";

const tokenKey = "token";

http.setJwt(getJwt());

export function login(emailObject) {
  return http.post(apiEndpoint, emailObject);
}

export function forgotPassword(emailObject) {
  return http.post(apiEndpoint + "/forget-password", emailObject);
}

export function resetPassword(passwordObject, resetToken) {
  return http.put(
    apiEndpoint + "/password-reset/" + resetToken,
    passwordObject
  );
}

export function logout() {
  sessionStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function validateToken(jwt) {
  try {
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function setJWT(jwt) {
  http.setJwt(jwt);
  sessionStorage.setItem(tokenKey, jwt);
}

export function getJwt() {
  return sessionStorage.getItem(tokenKey);
}

const authObject = {
  login,
  logout,
  setJWT,
  getCurrentUser,
  getJwt,
};

export default authObject;
