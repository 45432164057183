import { ModelWrapper } from "../../../components";
import * as Yup from "yup";
import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import {
  ArticleDropDown,
  Form,
  FormInput,
  SubmitLoadingButton,
} from "../../../../../components/Forms";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createNotification } from "../../../../../services/notificationService";
import { getAllActiveArticle } from "../../../../../services/articleService";
import { ErrorMessage, Loader } from "../../../../../components";
import * as toast from "../../../../../Toast";
import RadioButtonGroup from "../../../../../components/Forms/RadioButtonGroup";

const options = ["Article", "Insight"];

function ArticleNotificationFormModal({
  showModal,
  toggleModal,
  title,
  subtitle,
}) {
  // insight, acticle
  const [eventType, setEventType] = useState("");

  if (!showModal) return null;

  return (
    <ModelWrapper toggleModal={toggleModal} title={title} subtitle={subtitle}>
      <TextField
        select
        id="eventType"
        size="small"
        label="Notification Type"
        onChange={(e) => {
          setEventType(e.target.value);
        }}
        sx={{
          mb: 1,
        }}
        value={eventType}
      >
        {options.map((option, index) => (
          <MenuItem value={option} key={index}>
            {option}
          </MenuItem>
        ))}
      </TextField>

      {eventType === "Article" && (
        <ArticleNotificationFormWrapper
          toggleModal={toggleModal}
          eventType={eventType}
        />
      )}

      {eventType === "Insight" && (
        <InsightNotificationForm
          toggleModal={toggleModal}
          eventType={eventType}
        />
      )}
    </ModelWrapper>
  );
}

// login validationSchema
const articleNotificationFormSchema = Yup.object().shape({
  // notificationTitle: Yup.string()
  //   .required()
  //   .min(2)
  //   .max(200, "Notification Title must be less than 200 characters")
  //   .label("Notification Text")
  //   .trim(),
  notificationText: Yup.string()
    .required()
    .min(2)
    .max(200, "Notification Text must be less than 200 characters")
    .label("Notification Text")
    .trim(),
  referenceId: Yup.string().required().min(2).label("Article").trim(),
});

function ArticleNotificationFormWrapper({ eventType, toggleModal }) {
  const { isError, isLoading, data, error } = useQuery(
    ["articles"],
    () => getAllActiveArticle(),
    { refetchOnWindowFocus: true }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  return (
    <ArticleNotificationForm
      toggleModal={toggleModal}
      articleList={data.data.data["articles"]}
      eventType={eventType}
    />
  );
}

function ArticleNotificationForm({ eventType, toggleModal, articleList }) {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data) => createNotification(data),
  });
  const queryClient = useQueryClient();

  const handleSubmit = async (inputs) => {
    try {
      const notificationTitle = articleList.find(
        (article) => article.id === inputs.referenceId
      ).articleTitle;

      await mutateAsync({
        eventType: eventType.toLowerCase(),
        notificationTitle,
        notificationTarget: "all",
        ...inputs,
      });
      toggleModal();
      toast.success("Successfully Added Notification");
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    } catch (error) {
      toast.error("Notification could not be created");
    }
  };

  return (
    <Form
      initialValues={{
        notificationText: "",
        // notificationTitle: "",
        referenceId: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={articleNotificationFormSchema}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <ArticleDropDown
          label="Choose Article"
          name="referenceId"
          articleList={articleList}
        />

        {/* <FormInput
          label="Notification Title"
          name="notificationTitle"
          type="text"
        /> */}

        <FormInput
          label="Notification Text"
          name="notificationText"
          type="text"
        />

        <Stack spacing={2} direction="row">
          <Button fullWidth variant="outlined" onClick={toggleModal}>
            CANCEL
          </Button>
          <SubmitLoadingButton
            buttonText="Send Notification"
            type="submit"
            submitButton={true}
            loading={isLoading}
          />
        </Stack>
        {/* <SuccessAndErrorMessage error={state.error} success={state.message} /> */}
      </Box>
    </Form>
  );
}

// insight validationSchema
const insightNotificationFormSchema = Yup.object().shape({
  notificationTarget: Yup.string()
    .required()
    .min(2)
    .label("Notification Target")
    .trim(),
  notificationTitle: Yup.string()
    .required()
    .min(2)
    .max(200, "Notification Title must be less than 200 characters")
    .label("Notification Text")
    .trim(),
  notificationText: Yup.string()
    .required()
    .min(2)
    .max(200, "Notification Text must be less than 200 characters")
    .label("Notification Text")
    .trim(),
});

const insightOptions = [
  { key: "All", value: "all" },
  { key: "Subscribed", value: "subscribed" },
];

function InsightNotificationForm({ eventType, toggleModal, articleList }) {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data) => createNotification(data),
  });
  const queryClient = useQueryClient();

  const handleSubmit = async (inputs) => {
    try {
      await mutateAsync({
        eventType: eventType.toLowerCase(),
        ...inputs,
      });
      toggleModal();
      toast.success("Successfully Added Notification");
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    } catch (error) {
      toast.error("Notification could not be created");
    }
  };

  return (
    <Form
      initialValues={{
        notificationText: "",
        notificationTitle: "",
        notificationTarget: "all",
        // referenceId: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={insightNotificationFormSchema}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <RadioButtonGroup
          name="notificationTarget"
          options={insightOptions}
          onChange={(value) => {}}
        />
        <FormInput
          label="Notification Title"
          name="notificationTitle"
          type="text"
        />

        <FormInput
          label="Notification Text"
          name="notificationText"
          type="text"
        />

        <Stack spacing={2} direction="row">
          <Button fullWidth variant="outlined" onClick={toggleModal}>
            CANCEL
          </Button>
          <SubmitLoadingButton
            buttonText="Send Notification"
            type="submit"
            submitButton={true}
            loading={isLoading}
          />
        </Stack>
        {/* <SuccessAndErrorMessage error={state.error} success={state.message} /> */}
      </Box>
    </Form>
  );
}

export default ArticleNotificationFormModal;
