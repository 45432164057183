import axios from "axios";
import * as toast from "../Toast";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 409 &&
    error.response.status <= 500;

  if (expectedError) {
    toast.error(`An unexpected error occurrred, Internal Server Error.`);
    // alert("An unexpected error occurrred, Internal Server Error");
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["Content-Type"] = "application/json";

// console.log(process.env.ENVIRONMENT);

// let baseUrl = process.env.DEV_BASE_URL;
// if (process.env.ENVIRONMENT === "production") {
//   baseUrl = process.env.PROD_BASE_URL;
// } else {
//   baseUrl = process.env.DEV_BASE_URL;
// }

export const baseUrl = process.env.REACT_APP_BASE_URL;

const apiUrl = `${baseUrl}v1/`;

const httpMethodsObject = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setJwt,
  apiUrl,
  baseUrl,
};

export default httpMethodsObject;
