import { Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
// components
import {
  ConfirmationModal,
  CustomPagination,
  StyledDataGrid,
} from "../../../components";
import useVisible from "../../../../../hooks/useVisible";
import useResponse from "../../../../../hooks/useResponse";

import * as toast from "../../../../../Toast";
import { deleteNotification } from "../../../../../services/notificationService";
import { useState } from "react";
import { useQueryClient } from "react-query";

function ListingTable({ columns, data }) {
  const [searchParams, setSearchParams] = useSearchParams();

  let page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
  page = page - 1;

  const [isDeleteMode, toggleDeleteMode] = useVisible();
  const [state, dispatch] = useResponse();

  const [notificationId, setNotificationId] = useState("");

  const queryClient = useQueryClient();

  const handleCellClick = (params) => {
    const row = params.row;
    const value = params.field;

    if (value === "delete") {
      setNotificationId(row.id);
      return toggleDeleteMode();
    }
  };

  const handleDeleteConfirm = async () => {
    dispatch.sendingRequst();
    try {
      const response = await deleteNotification(notificationId);
      const data = await response.data;
      toggleDeleteMode();

      queryClient.invalidateQueries({ queryKey: ["notifications"] });

      toast.success(data.message);
      dispatch.successResponse(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <>
      <ConfirmationModal
        title="Are you sure you want to delete this notification?"
        showModal={isDeleteMode}
        toggleModal={toggleDeleteMode}
        onConfirm={handleDeleteConfirm}
        loading={state.loading}
        error={state.error}
      />
      <StyledDataGrid
        rows={data.result}
        columns={columns}
        rowCount={data.total}
        pageSize={10}
        rowsPerPageOptions={[10]}
        page={page}
        onPageChange={(page) => {
          setSearchParams({ page: page + 1 });
        }}
        disableColumnMenu
        disableColumnSelector
        disableColumnFilter
        paginationMode="server"
        onCellClick={handleCellClick}
        onFilterModelChange={(model) => console.log(model)}
        // loading={loading}
        hideFooterSelectedRowCount={true}
        getRowClassName={(params) => {
          return params.row.checkInDone ? "awaiting-response" : "";
        }}
        autoHeight
        components={{
          Pagination: CustomPagination,
          // ColumnSortedAscendingIcon: MdArrowDropUp,
          // ColumnSortedDescendingIcon: MdArrowDropDown,
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center"
              style={{ fontSize: "1.5rem" }}
            >
              No rows
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center"
              style={{ fontSize: "1.5rem" }}
            >
              No result
            </Stack>
          ),
        }}
      />
    </>
  );
}

export default ListingTable;
