import moment from "moment";
import React, { useCallback, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Stack, Button } from "@mui/material";
import { useQuery } from "react-query";

// components
import { ArticleNotificationFormModal, ListingTable } from "./component";
import { ErrorMessage, Loader } from "../../../../components";

// hooks
import useVisible from "../../../../hooks/useVisible";
import useResponse from "../../../../hooks/useResponse";

// services
import {
  deleteArticle,
  getAllActiveArticle,
  getAllArticleWithPagination,
} from "../../../../services/articleService.js";

import * as toast from "../../../../Toast";
import { senArticleNotifiction } from "../../../../services/articleNotificationService";
import { ConfirmationModalMUI } from "../../components";

function ArticleListing({ onAdd, onEdit, onDetail }) {
  const { isError, isLoading, data, error } = useQuery(
    ["locations"],
    () => getAllActiveArticle(),
    { refetchOnWindowFocus: true }
  );

  const [isAddShow, toggleAddModal] = useVisible(false);
  const [articleNotificationConfirm, toggleArticleNotificationConfirm] =
    useVisible(false);
  const [state, dispatch] = useResponse();

  const [articleNotificaton, setArticleNotificaton] = useState(null);

  const controller = useCallback(
    async (data) => await getAllArticleWithPagination(data),
    []
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const handleSendArticleNotificationConfirm = async () => {
    dispatch.sendingRequst();
    try {
      const response = await senArticleNotifiction(articleNotificaton);
      const data = await response.data;
      toggleArticleNotificationConfirm();
      toggleAddModal();
      toast.success(data.message);
      dispatch.successResponse(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleSubmit = (articleNotificationData) => {
    setArticleNotificaton(articleNotificationData);
    toggleArticleNotificationConfirm();
  };

  const articleList = data.data.data["articles"];

  return (
    <>
      <ConfirmationModalMUI
        subtitle="Are you sure you want to send notification for this article?"
        // note="Deleting the location will remove the Races and Tips associated with it."
        showModal={articleNotificationConfirm}
        toggleModal={() => {
          toggleArticleNotificationConfirm();
          dispatch.reset();
        }}
        onConfirm={handleSendArticleNotificationConfirm}
        loading={state.loading}
        error={state.error}
        cancelButtonText="Not Now"
        confirmButtonText="Send"
      />
      <ArticleNotificationFormModal
        title="Send Article Notifications"
        showModal={isAddShow}
        toggleModal={() => {
          toggleAddModal();
          dispatch.reset();
        }}
        onSubmit={handleSubmit}
        articleList={articleList}
      />
      <Stack spacing={5} padding={3}>
        <Stack
          direction="row"
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "end",
          }}
        >
          {/* <Button variant="outlined" size="large" onClick={toggleAddModal}>
            Send Article Notification
          </Button> */}
          <Button
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            onClick={onAdd}
          >
            Add
          </Button>
        </Stack>
        <Stack>
          <ListingTable
            columns={columns}
            onDetail={onDetail}
            onEditClick={onEdit}
            controller={controller}
            deleteRecord={deleteArticle}
            deleteModelTitle="Do you want to delete this article?"
          />
        </Stack>
      </Stack>
    </>
  );
}

export default ArticleListing;

const columns = [
  {
    field: "articleTitle",
    headerName: "Article Title",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    width: 80,
    valueGetter: (params) => `${params.row.status ? "Active" : "Inactive"}`,
  },
  {
    field: "articleDate",
    headerName: "Publish Date",
    // flex: 1,
    width: 110,
    valueGetter: (params) =>
      `${moment(params.row.articleDate).format("DD-MMM YYYY")}`,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 110,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("DD-MMM YYYY")}`,
  },
  {
    field: "edit",
    headerName: "Edit",
    sortable: false,
    minWidth: 110,
    headerAlign: "center",
    align: "center",
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: () => {
      return <Button>Edit</Button>;
    },
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    minWidth: 140,
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return <Button color="error">delete</Button>;
    },
  },
];
