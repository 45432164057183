import http from "./httpService";

const apiEndpoint = http.apiUrl + "insight";

function locationURl(id) {
  return `${apiEndpoint}/${id}`;
}

const constructionURL = (API_URL_ROUTE, { page, limit }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page + 1);
  url.searchParams.set("limit", limit);

  return url;
};

// service is used to get all insights
export function getAllInsight() {
  return http.get(apiEndpoint);
}

// service is used to get all insights with pagination
export function getAllInsightWithPagination({ page = 0, limit = 10 }) {
  const url = constructionURL("/listing", { page, limit });
  return http.get(url);
}

// service is used to get details of single location
export function getSingleInsight(id) {
  return http.get(locationURl(id));
}

// service is used to create location
export function createInsight(data) {
  return http.post(apiEndpoint, data);
}

// service is used to update Insight
export function updateInsight(id, data) {
  return http.put(locationURl(id), data);
}

// service is used to delete location
export function deleteInsight(id) {
  return http.delete(locationURl(id));
}
