import * as React from "react";
import { Stack, Modal, Button, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { HeaderTitleSubtitle, ErrorMessage } from "../../../components";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ConfirmationModalMUI({
  showModal,
  toggleModal,
  onConfirm,
  title,
  subtitle,
  note,
  error,
  loading = false,
  confirmButtonText = "CONFIRM",
  cancelButtonText = "Cancel",
}) {
  return (
    <React.Fragment>
      <Modal
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        {/* <Box sx={{ ...style, width: 200 }}>
          <h2 id="child-modal-title">Text in a child modal</h2>
          <p id="child-modal-description">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          </p>
          <Button onClick={handleClose}>Close Child Modal</Button>
        </Box> */}

        {/* <Card className={`${styles.modalCard} ${styles.confirmModal}`}> */}
        <Box sx={{ ...style }}>
          <Stack spacing={0.5} className="mb-10">
            <HeaderTitleSubtitle
              title={title}
              subtitle={subtitle}
              note={note}
            />
            {error && <ErrorMessage message={error} />}
          </Stack>
          <Stack spacing={2} direction="row">
            <Button fullWidth variant="outlined" onClick={toggleModal}>
              {cancelButtonText}
            </Button>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              onClick={onConfirm}
              loading={loading}
              disabled={loading}
            >
              {loading ? "loading..." : confirmButtonText}
            </LoadingButton>
          </Stack>
        </Box>
        {/* </Card> */}
      </Modal>
    </React.Fragment>
  );
}

export default ConfirmationModalMUI;
