import React, { useEffect } from "react";
import * as Yup from "yup";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { Link, Box } from "@mui/material";
import { CardContainer, Card } from "../../components/UI";
import { HeaderTitleSubtitle, SuccessAndErrorMessage } from "../../components";

import {
  Form,
  FormInputPassword,
  SubmitLoadingButton,
} from "../../components/Forms";

import useResponse from "../../hooks/useResponse";
import trimObjectValues from "../../utils/trimObject";
import * as authService from "../../services/authService";
import * as toast from "../../Toast";

import styles from "../login/login.module.css";

// @reference https://stackoverflow.com/questions/12090077/javascript-regular-expression-password-validation-having-special-characters
// reset password validationSchema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .min(5)
    .max(30)
    .trim()
    .test("type", "Password must not contain Whitespaces.", (value) => {
      if (!value) return true;

      const isNonWhiteSpace = /^\S*$/;
      return isNonWhiteSpace.test(value);
    })
    .test(
      "type",
      "Password must contain at least one Special Character(Special characters @ # $ ! allowed).",
      (value) => {
        if (!value) return true;

        const isContainsSymbol = /^(?=.*[~`!@#$]).*$/;
        return isContainsSymbol.test(value);
      }
    )
    .label("Password"),
  cPassword: Yup.string()
    .required()
    .min(5)
    .max(30)
    .label("Confirm Password")
    .oneOf([Yup.ref("password")], "Your passwords do not match.")
    .trim(),
});

const ResetPassword = () => {
  const [state, dispatch] = useResponse();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const user = authService.validateToken(token);
    if (!user) {
      navigate("not-found");
    }
  }, [token, navigate]);

  const handleSubmit = async (loginData, formkiObject) => {
    dispatch.sendingRequst();
    loginData = trimObjectValues(loginData);
    try {
      const response = await authService.resetPassword(
        {
          password: loginData.password,
        },
        token
      );
      toast.success(response.data.message);
      window.location = "/login";
      formkiObject.resetForm();
      dispatch.successResponse(response.data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <CardContainer>
      <Card>
        <div className={styles.headingContainer}>
          <HeaderTitleSubtitle
            title="Reset Password"
            subtitle="Enter your new password."
          />
        </div>
        <Form
          initialValues={{
            password: "",
            cPassword: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
            }}
          >
            <FormInputPassword
              label="Password"
              name="password"
              type="password"
            />
            <FormInputPassword
              label="Confirm Password"
              name="cPassword"
              type="password"
            />
            <SubmitLoadingButton
              buttonText="Reset Password"
              type="submit"
              submitButton={true}
              loading={state.loading}
            />
            <SuccessAndErrorMessage
              error={state.error}
              success={state.message}
            />
            <Link
              variant="subtitle1"
              component={RouterLink}
              to="/login"
              align="center"
            >
              Login
            </Link>
          </Box>
        </Form>
      </Card>
    </CardContainer>
  );
};

export default ResetPassword;
