import moment from "moment";
import React, { useCallback } from "react";
import { Stack, Button } from "@mui/material";
import { ListingTable } from "./components";

// services
import {
  getAllUserWithPagination,
  deleteUser,
} from "../../../../services/userService";

function UserListing() {
  return (
    <Stack spacing={5} padding={3}>
      <Stack>
        <ListingTable
          columns={columns}
          controller={useCallback(
            async (data) => await getAllUserWithPagination(data),
            []
          )}
          deleteModelTitle="Do you want to delete this user?"
          deleteRecord={deleteUser}
        />
      </Stack>
    </Stack>
  );
}

export default UserListing;

const columns = [
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "fullName",
    headerName: "Name",
    minWidth: 170,
    // flex: 1,
    valueGetter: (params) => ` ${params.row.fullName || "-"}`,
  },
  // {
  //   field: "dob",
  //   headerName: "DOB",
  //   minWidth: 150,
  //   // flex: 1,
  //   valueGetter: (params) =>
  //     ` ${params.row.dob ? moment(params.row.dob).format("DD-MMM YYYY") : "-"}`,
  // },
  {
    field: "isSubscribed",
    headerName: "Subscribed",
    minWidth: 150,
    // flex: 1,
    valueGetter: (params) =>
      `${params.row.isSubscribed ? "Subscribed" : "Not Subscribed"}`,
  },
  {
    field: "createdAt",
    headerName: "Registered At",
    minWidth: 150,
    // flex: 1,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("DD-MMM YYYY")}`,
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    minWidth: 80,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return <Button color="error">delete</Button>;
    },
  },
];
