import React, { useState } from "react";
import { Button, Stack } from "@mui/material";
import { ErrorMessage, useField } from "formik";
import SuccessAndErrorMessage from "../SuccessAndErrorMessage";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

// services
import http from "../../services/httpService";

function FormVideoInput({
  name,
  videoValues,
  checkResolution,
  onChangeSelectedVideo,
  onError,
  onCheckRes,
}) {
  const [field] = useField(name);
  const [preview, setPreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    if (!event.target.files) {
      return;
    }

    const selectedFile = event.target.files[0];
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    onChangeSelectedVideo(event);

    // Validate resolutions or file size if needed
    const video = document.createElement("video");
    video.src = objectUrl;

    video.onloadedmetadata = () => {
      //   const duration = video.duration;
      //   const width = video.videoWidth;
      //   const height = video.videoHeight;

      //   // Example: check resolution (optional validation)
      //   if (width < 600 || height < 600) {
      //     onError("Video resolution should be at least 600x600 pixels.");
      //     setErrorMessage("Video resolution should be at least 600x600 pixels.");
      //     onCheckRes(true);
      //   } else {
      onCheckRes(false);
      setErrorMessage("");
      //   }
    };

    video.onerror = () => {
      onError("Input file contains unsupported video format.");
      setErrorMessage("Input file contains unsupported video format.");
      onCheckRes(true);
    };
  };

  return (
    <>
      <Stack>
        <Button
          component="label"
          variant="outlined"
          startIcon={<FileUploadOutlinedIcon />}
          sx={{ margin: 0 }}
        >
          Upload Video {field.value && `- ${field.value.name}`}
          <input
            type="file"
            accept="video/*"
            hidden
            name={name}
            onBlur={field.onBlur}
            onChange={handleFileChange}
          />
        </Button>
        <p className="note">
          <span className="w-700">Resolution : </span>
          Upload a video (Less than 20 MB in size)
        </p>
      </Stack>
      {(videoValues || field.value) && (
        <video
          src={field.value ? preview : `${http.baseUrl}videos/${videoValues}`}
          controls
          width={300}
          height={150}
        />
      )}
      <ErrorMessage name={name}>
        {(message) => <SuccessAndErrorMessage error={message} />}
      </ErrorMessage>
      {checkResolution && field.value && (
        <SuccessAndErrorMessage error={errorMessage} />
      )}
    </>
  );
}

export default FormVideoInput;
