import { MenuItem, TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";

function ArticleDropDown({ label, name, articleList = [], disabled = false }) {
  const [field, meta] = useField(name);

  return (
    <TextField
      select
      id="select"
      size="small"
      label={label}
      {...field}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
      disabled={disabled}
    >
      {articleList.map((articleItem) => (
        <MenuItem value={articleItem.id} key={articleItem.id}>
          {articleItem.articleTitle}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default ArticleDropDown;
