import React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "../../components";
// notification pages
import NotificationListing from "./NotificationListing";

function Notification() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<NotificationListing />} />
      </Routes>
    </Layout>
  );
}

export default Notification;
