import React from "react";
import { Formik } from "formik";

function AppForm({ initialValues, onSubmit, validationSchema, children }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        return (
          <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            {children}
          </form>
        );
      }}
    </Formik>
  );
}

export default AppForm;
