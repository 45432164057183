import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { Sidebar } from "./components";

// screens
import User from "./pages/User/User";
import Location from "./pages/Location/Location";
import RaceEvent from "./pages/RaceEvent/RaceEvent";
import Tips from "./pages/Tips/Tips";
import Article from "./pages/Article/Article";
import Config from "./pages/Config/Config";

import styles from "./dashboard.module.css";
import Insight from "./pages/Insight/Insight";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import Notification from "./pages/Notification/Notification";

function Dashboard() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <div>
      {!matches && (
        <nav className={styles.nav}>
          <IconButton aria-label="nav-toggle" onClick={toggleDrawer(true)}>
            <MenuTwoToneIcon />
          </IconButton>
        </nav>
      )}

      <main className={styles.container}>
        <Sidebar toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
        <div className={styles.mainContainer}>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard/home" />} />
            <Route path="/home" element={<User />} />
            <Route path="/locations/*" element={<Location />} />
            <Route path="/races/*" element={<RaceEvent />} />
            <Route path="/articles/*" element={<Article />} />
            <Route path="/insights/*" element={<Insight />} />
            <Route path="/notifications/*" element={<Notification />} />

            <Route path="/tips/*" element={<Tips />} />
            <Route path="/config" element={<Config />} />
          </Routes>
        </div>
      </main>
    </div>
  );
}

export default Dashboard;
