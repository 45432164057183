import { useMutation } from "react-query";
import http from "./httpService";

const apiEndpoint = http.apiUrl + "notification";

function notificationURl(id) {
  return `${apiEndpoint}/${id}`;
}

const constructionURL = (API_URL_ROUTE, { page, limit }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page + 1);
  url.searchParams.set("limit", limit);

  return url;
};

// service is used to get all notifications
export function getAllNotification() {
  return http.get(apiEndpoint);
}

// service is used to get all notifications
export function getAllActiveNotification() {
  return http.get(apiEndpoint + "/active-notification");
}

// service is used to get all notifications with pagination
export function getAllNotificationWithPagination({ page = 0, limit = 10 }) {
  const url = constructionURL("/listing", { page, limit });
  return http.get(url);
}

// service is used to get details of single notification
export function getSingleNotification(id) {
  return http.get(notificationURl(id));
}

// service is used to create notification
export function createNotification(data) {
  return http.post(apiEndpoint, data);
}

// service is used to update notification
export function updateNotification(id, data) {
  return http.put(notificationURl(id), data);
}

// service is used to delete notification
export function deleteNotification(id) {
  return http.delete(notificationURl(id));
}

export function useCreateNotificationMutation() {
  return useMutation({
    mutationFn: (data) => createNotification(data),
  });
}
