import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../../assets/logo.png";

import styles from "./sideNav.module.css";
import { Drawer, Typography, useMediaQuery, useTheme } from "@mui/material";

import ConfirmationModal from "./ConfirmationModal";

import useVisible from "../../../hooks/useVisible";

import * as toast from "../../../Toast";

import * as authService from "../../../services/authService";

// icons
import PeopleIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import InsightsIcon from "@mui/icons-material/Insights";
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import PeopleIcon from "@mui/icons-material/People";
// import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
// import ArticleIcon from "@mui/icons-material/Article";
// import SettingsIcon from "@mui/icons-material/Settings";
// import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
// import LogoutIcon from "@mui/icons-material/Logout";

const NavLinkList = [
  {
    id: "273044b2-da6f-4c66-b906-6d2486a2cda5",
    to: "/dashboard/home?page=1",
    menuName: "Users",
    icon: PeopleIcon,
  },
  {
    id: "8a84e7b4-b5e0-46eb-a4bd-50f3cf406c0f",
    to: "/dashboard/locations?page=1",
    menuName: "Locations",
    icon: LocationOnIcon,
  },
  {
    id: "ab9d4bd7-3f7d-4fe1-b969-2ca1bb9cbea9",
    to: "/dashboard/races?page=1",
    menuName: "Race Calendar",
    icon: DirectionsCarIcon,
  },
  {
    id: "ab9d4bd7-3f7d-4fe1-b969-2ca1bb7cbea9",
    to: "/dashboard/insights?page=1",
    menuName: "Early Insights",
    icon: InsightsIcon,
  },
  {
    id: "273044b2-da6f-4c66-b906-6d2486222cda5",
    to: "/dashboard/tips?page=1",
    menuName: "Tips",
    icon: TipsAndUpdatesIcon,
  },
  {
    id: "643cc41d-01d1-44bc-ad37-38251db7f325",
    to: "/dashboard/articles?page=1",
    menuName: "Articles",
    icon: ArticleIcon,
  },
  {
    id: "ab9d4bd7-3f7d-4fe1-b969-2ca1bb7cbea10",
    to: "/dashboard/notifications?page=1",
    menuName: "Notification",
    icon: NotificationAddOutlinedIcon,
  },
  {
    id: "643cc41d-01d1-44bc-ad37-38151db7f325",
    to: "/dashboard/config",
    menuName: "Configuration",
    icon: SettingsIcon,
  },
];

function SideNav({ toggleLogoutModal }) {
  return (
    <div className={styles.sideNavContainer}>
      <div className={styles.sideNav}>
        <div className={styles.logoHeader}>
          <img
            className={styles.logoImage}
            src={Logo}
            title="make it fit logo"
            width="80"
            height="80"
            alt="make it fit logo"
          />
          <Typography
            style={{ fontWeight: "bold", color: "white", marginLeft: 5 }}
          >
            THE SIXTH DAY RACING
          </Typography>
        </div>
        <div className={styles.menuList}>
          {NavLinkList.map(({ menuName, icon: Icon, to, id }) => (
            <NavLink
              key={id}
              to={to}
              className={(navData) =>
                navData.isActive
                  ? `${styles.active} ${styles.menuItem}`
                  : styles.menuItem
              }
            >
              <Icon className={styles.icon} />
              {/* <img src={blackIcon} className={styles.icon} alt="Nav icons" /> */}
              <span>{menuName}</span>
            </NavLink>
          ))}

          <div className={styles.menuItem} onClick={toggleLogoutModal}>
            <LogoutIcon className={styles.icon} />
            <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Sidebar({ isDrawerOpen, toggleDrawer }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [showLogoutModal, toggleLogoutModal] = useVisible(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    authService.logout();
    toast.success("Successfully logged out!");
    toggleLogoutModal();
    // window.location.reload();

    navigate("/");
  };

  // if (matches) {
  //   return ;
  // } else {
  //   return (
  //     <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
  //       <SideNav />
  //     </Drawer>
  //   );
  // }

  return (
    <>
      <ConfirmationModal
        title="Are you sure you want to logout?"
        showModal={showLogoutModal}
        toggleModal={toggleLogoutModal}
        onConfirm={handleLogout}
        ConfirmButtonText="LOGOUT"
      />
      {matches ? (
        <SideNav
          toggleLogoutModal={() => {
            toggleDrawer(false)({ event: "" });
            toggleLogoutModal();
          }}
        />
      ) : (
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <SideNav
            toggleLogoutModal={() => {
              toggleDrawer(false)({ event: "" });
              toggleLogoutModal();
            }}
          />
        </Drawer>
      )}
    </>
  );
}
