import http from "./httpService";

const apiEndpoint = http.apiUrl + "article";

function articleURl(id) {
  return `${apiEndpoint}/${id}`;
}

const constructionURL = (API_URL_ROUTE, { page, limit }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page + 1);
  url.searchParams.set("limit", limit);

  return url;
};

// service is used to get all articles
export function getAllArticle() {
  return http.get(apiEndpoint);
}

// service is used to get all articles
export function getAllActiveArticle() {
  return http.get(apiEndpoint + "/active-article");
}

// service is used to get all articles with pagination
export function getAllArticleWithPagination({ page = 0, limit = 10 }) {
  const url = constructionURL("/listing", { page, limit });
  return http.get(url);
}

// service is used to get details of single article
export function getSingleArticle(id) {
  return http.get(articleURl(id));
}

// service is used to create article
export function createArticle(data) {
  return http.post(apiEndpoint, data);
}

// service is used to update article
export function updateArticle(id, data) {
  return http.put(articleURl(id), data);
}

// service is used to delete article
export function deleteArticle(id) {
  return http.delete(articleURl(id));
}
