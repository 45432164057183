import moment from "moment";
import React, { useState } from "react";
import { Stack, Button } from "@mui/material";
import { useQuery } from "react-query";

// components
import { NotificationFormModal, ListingTable } from "./component";
import { ErrorMessage, Loader } from "../../../../components";

// hooks
import useVisible from "../../../../hooks/useVisible";
import useResponse from "../../../../hooks/useResponse";

import * as toast from "../../../../Toast";
import { ConfirmationModalMUI } from "../../components";
import {
  createNotification,
  getAllNotificationWithPagination,
} from "../../../../services/notificationService";
import { useSearchParams } from "react-router-dom";

function NotificationListing() {
  const [searchParams] = useSearchParams();

  let page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
  page = page - 1;
  const { isError, isLoading, data, error } = useQuery(
    ["notifications", page],
    () => getAllNotificationWithPagination({ page: page }),
    { refetchOnWindowFocus: true, cacheTime: 100 }
  );

  const [isAddShow, toggleAddModal] = useVisible(false);
  const [notificationNotificationConfirm, toggleArticleNotificationConfirm] =
    useVisible(false);
  const [state, dispatch] = useResponse();

  const [notificationNotificaton, setArticleNotificaton] = useState(null);

  const handleSendArticleNotificationConfirm = async () => {
    dispatch.sendingRequst();
    try {
      const response = await createNotification(notificationNotificaton);
      const data = await response.data;
      toggleArticleNotificationConfirm();
      toggleAddModal();
      toast.success(data.message);
      dispatch.successResponse(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleSubmit = (notificationNotificationData) => {
    setArticleNotificaton(notificationNotificationData);
    toggleArticleNotificationConfirm();
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  return (
    <>
      <ConfirmationModalMUI
        subtitle="Are you sure you want to send notification for this notification?"
        // note="Deleting the location will remove the Races and Tips associated with it."
        showModal={notificationNotificationConfirm}
        toggleModal={() => {
          toggleArticleNotificationConfirm();
          dispatch.reset();
        }}
        onConfirm={handleSendArticleNotificationConfirm}
        loading={state.loading}
        error={state.error}
        cancelButtonText="Not Now"
        confirmButtonText="Send"
      />
      <NotificationFormModal
        title="Create Notification"
        showModal={isAddShow}
        toggleModal={() => {
          toggleAddModal();
          dispatch.reset();
        }}
        onSubmit={handleSubmit}
        // notificationList={notificationList}
        notificationList={[]}
      />
      <Stack spacing={5} padding={3}>
        <Stack
          direction="row"
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "end",
          }}
        >
          <Button variant="outlined" size="large" onClick={toggleAddModal}>
            Create Notification
          </Button>
        </Stack>
        <Stack>
          <ListingTable
            columns={columns}
            data={data.data.data}
            deleteModelTitle="Do you want to delete this notification?"
          />
        </Stack>
      </Stack>
    </>
  );
}

export default NotificationListing;

const columns = [
  {
    field: "notificationTitle",
    headerName: "Title",
    width: 500,
  },

  {
    field: "notificationText",
    headerName: "Text",
    width: 300,
  },

  {
    field: "eventType",
    headerName: "Type",
    width: 100,
  },

  {
    field: "createdAt",
    headerName: "Created At",
    width: 110,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("DD-MMM YYYY")}`,
  },

  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    maxWidth: 140,
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return <Button color="error">delete</Button>;
    },
  },
];
